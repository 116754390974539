import React from 'react';
import ReactPlayer from 'react-player'


const videos = [
  {
    name: "Carla Simulator",
    homepage: "https://carla.org/",
    url: "https://youtu.be/t4t4Oy2TCps"
    // url: "videos/carla_replay_crop_comp.mp4"
    // url: "https://www.youtube.com/watch?v=AZhzZy57XeU"
    // url:   "https://g-b0ef78.1d0d8d.03c0.data.globus.org/media/videos/hosted/carla_replay_crop_comp.mp4"
  },
  {
    name: "Kitti Dataset",
    homepage: "https://www.cvlibs.net/datasets/kitti/",
    url: "https://youtu.be/PwKhAoskfxw"
    // url: "videos/kitti_replay_cropped.mp4"
    // url: "https://g-b0ef78.1d0d8d.03c0.data.globus.org/media/videos/hosted/kitti_replay_cropped.mp4"
  },
  {
    name: "nuScenes Dataset",
    homepage: "https://www.nuscenes.org/nuscenes",
    url: "https://youtu.be/1FCwgxw87nc"
    // url: "videos/nuscenes_replay_cropped.mp4"
    // url: "https://g-b0ef78.1d0d8d.03c0.data.globus.org/media/videos/hosted/nuscenes_replay_cropped.mp4"
  },
  {
    name: "MOT15 Dataset",
    homepage: "https://motchallenge.net/data/MOT15/",
    url: "https://youtu.be/zw64pQzcBfc"
    // url: "videos/MOT15_replay_cropped_rate_comp.mp4"
    // url: "https://g-b0ef78.1d0d8d.03c0.data.globus.org/media/videos/hosted/MOT15_replay_cropped_rate_comp.mp4"
  }
]


const AutoVideo = ({name, url, homepage}) =>
  <div key={name} className='player-wrapper'>
    <a href={homepage} rel="noopener noreferrer" target="_blank"><h3>{name}</h3></a>
    <div className='grid-item'>
      <ReactPlayer
        className='react-player'
        url={url}
        width='80%'
        height='30vh'
        controls={false}
        muted={true}
        autoPlay={true}
        loop={true}
        playing={true}
      />
    </div>
  </div>
      

const VideoTeasers = () => {
  return (
    <div className="grid-container reveal-from-bottom" data-reveal-delay="200">
      {videos.map(AutoVideo)}
    </div>
  );
}

export default VideoTeasers;