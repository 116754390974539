import React from 'react';
import classNames from 'classnames';

// import sections
// import AboutDescription from '../components/sections/AboutDescription'
import AboutTeam from '../components/sections/AboutTeam';

const About = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  return (
    <section {...props} className={outerClasses}>
      {/* <AboutDescription /> */}
      <div className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
        <AboutTeam />
      </div>
    </section>
  );
}
  
export default About;