import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDr5lcZIbcQkMT8KClSMrDvEaF3V6RT2ZI",
  authDomain: "avstack-7b4ee.firebaseapp.com",
  projectId: "avstack-7b4ee",
  storageBucket: "avstack-7b4ee.appspot.com",
  messagingSenderId: "149504999316",
  appId: "1:149504999316:web:039ecbe85071acf97e9981"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const database = getFirestore(app)

export default database
