import React from 'react';
import PropTypes from 'prop-types';
import { CodeBlock, dracula } from "react-code-blocks";


const propTypes = {
    code: PropTypes.string,
    language: PropTypes.string,
    showLineNumbers: PropTypes.bool,
    startingLineNumber: PropTypes.string,
}
  
const defaultProps = {
    code: '',
    language: '',
    showLineNumbers: false,
    startingLineNumber: '1'
}

  
function MyCodeBlock({ code, language, showLineNumbers, startingLineNumber }) {
  return (
    <CodeBlock
      text={code}
      language={language}
      showLineNumbers={showLineNumbers}
      startingLineNumber={startingLineNumber}
      theme={dracula}
    />
  );
}

MyCodeBlock.propTypes = propTypes;
MyCodeBlock.defaultProps = defaultProps;

export default MyCodeBlock;