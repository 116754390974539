import React from 'react';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <HashLink to="/about#contact">Contact</HashLink>
        </li>
        <li>
          <HashLink to="/about">About us</HashLink>
        </li>
        <li>
          <HashLink to="/research">Research</HashLink>
        </li>
        <li>
          <HashLink to="/faq">FAQ</HashLink>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/avstack-lab/avstack/issues">Support</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;