import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from './Image';

const propTypes = {
    children: PropTypes.node,
    status: PropTypes.bool
  }
  
const defaultProps = {
    children: null,
    status: false
  }

const SplitLeftRight = ({
    className,
    titletext,
    headertext,
    bodytext,
    src,
    width,
    height,
    imageLink,
    imageFill,
    ...props
  }) => {

    return (
      <div className="split-item">
        <div className="split-item-content center-content-mobile reveal-from-left" style={{ textAlign: "left" }} data-reveal-container=".split-item">
          <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
            {titletext}
          </div>
          <h3 className="mt-0 mb-12">
            {headertext}
          </h3>
          <p className="m-0">
            {bodytext}
          </p>
        </div>
        <div className={
          classNames(
            'split-item-image center-content-mobile reveal-from-bottom',
            imageFill && 'split-item-image-fill'
          )}
          data-reveal-container=".split-item">
          <Image
            src={src}
            alt="Image not available"
            width={width}
            height={height}
            imagelink={imageLink}/>
        </div>
      </div>
    );
  }
  
  SplitLeftRight.propTypes = propTypes;
  SplitLeftRight.defaultProps = defaultProps;
  
  export default SplitLeftRight;