/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { SocialIcon } from 'react-social-icons';

// Media
import MiroImage from "../../assets/images/miroslav.png"
import SpencerImage from "../../assets/images/spencer.jpg"


const members = [
  { name: "Spencer Hallyburton",
    title: "Lead Developer",
    description: "Spencer is a PhD candidate in Duke's Cyber Physical Systems Lab (CPSL).",
    image: SpencerImage,
    personal: "https://shally.dev/",
    git: "https://github.com/roshambo919",
    linkedin: "https://www.linkedin.com/in/spencerhallyburton/",
    twitter: "https://twitter.com/spencehally",
  },
  { name: "Miroslav Pajic" ,
    title: "Principle Investigator",
    description: "Miroslav is the Dickinson Family Associate Professor in the Departments of Electrical and Computer Engineering and Computer Science and the director of the Cyber-Physical Systems Lab (CPSL).",
    image: MiroImage,
    personal: "https://people.duke.edu/~mp275/",
    git: "https://gitlab.oit.duke.edu/cpsl",
    linkedin: "https://www.linkedin.com/in/miroslav-pajic-72a0176/",
    twitter: "https://twitter.com/miroslav_pajic",
  },
]


const Member = ({image, name, title, description, twitter, git, linkedin, personal}) =>
  <div class="col-sm-6 col-md-4">
  <div class="team-item ">
  <div className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
    <img src={image} class="team-img" alt="Team member" />
    <h3>{name}</h3>
    <div class="team-info"><p>{title}</p></div>
    <p>{description}</p>
    <ul class="team-icon">
      <li><SocialIcon url={twitter}/></li>
      <li><SocialIcon url={git}/></li>
      <li><SocialIcon url={linkedin}/></li>
      <li><SocialIcon url={personal}/></li>
    </ul>
  </div>
  </div>
  </div>


function AboutTeam() {
  let message = `
    AVstack was built by the Duke University Cyber Physical Systems Lab (CPSL). 
    Over the years, the maturation of AVstack is indebted to many enthusiastic developers.
    The key drivers of the project are listed here.
    `;
  return (
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2 class="section-title">The Team Behind AVstack</h2>
            <p class="section-subtitle">{message}</p>
          </div>
          {members.map(Member)}
        </div>
      </div>
  )
}

export default AboutTeam;