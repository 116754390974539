import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import VideoTeasers from '../components/sections/VideoTeasers';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import QuickStart from '../components/sections/QuickStart'

// import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <div id="top">
        <Hero className="illustration-section-01" />
        <FeaturesTiles />
        <VideoTeasers />
        <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
        <QuickStart topDivider />
        {/* <Testimonial topDivider /> */}
        <Cta split />
      </div>
    </>
  );
}

export default Home;