import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import MyCodeBlock from '../elements/CodeBlock'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const QuickStart = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

const outerClasses = classNames(
  'hero section center-content',
  topOuterDivider && 'has-top-divider',
  bottomOuterDivider && 'has-bottom-divider',
  hasBgColor && 'has-bg-color',
  invertColor && 'invert-color',
  className
  );

const innerClasses = classNames(
  'hero-inner section-inner reveal-from-bottom',
  topDivider && 'has-top-divider',
  bottomDivider && 'has-bottom-divider'
);

const sectionHeader = {
  title: 'Get Started',
  paragraph: ''
};


const installAvdevSandbox =
`# Get started with the AV development sandbox
git clone --recurse-submodules https://github.com/avstack-lab/avdev-sandbox.git
./initialize.sh  # this will download percep models and datasets the first time
poetry install`;

// const installCore = 
// `# Install AVstack-core in some directory
// git clone --recurse-submodules https://github.com/avstack-lab/lib-avstack-core.git 
// poetry install
// cd models
// ./download_mmdet_models.sh
// ./download_mmdet3d_models.sh`;

// const InstallAPI =
// `# Install AVstack-api in the same directory (install Core first)
// git clone --recurse-submodules https://github.com/avstack-lab/lib-avstack-api.git
// poetry install
// cd data
// ./download_KITTI_object_data.sh
// ./download_KITTI_raw_tracklets.sh
// ./download_KITTI_raw_data.sh
// ./download_nuScenes_mini.sh`;

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} data-reveal-delay="200">
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="hero-content" style={{ textAlign: "left" }} id="quickstart">
            <div className="container-m mb-32">
              <div className="container-m mb-32 reveal-from-bottom" data-reveal-delay="200">
                Requirements (click for instructions):
                <ul>
                    <li> <a href={"https://www.how2shout.com/linux/install-python-3-9-or-3-8-on-ubuntu-22-04-lts-jammy-jellyfish/"} rel="noopener noreferrer" target="_blank">Python 3.8 </a></li>
                    <li> <a href={"https://releases.ubuntu.com/focal/"} rel="noopener noreferrer" target="_blank">Ubuntu (tested on 20.04, 22.04)</a></li>
                    <li> <a href={"https://python-poetry.org"} rel="noopener noreferrer" target="_blank">Pypoetry installed</a> </li>
                </ul>
              </div>
            </div>
            <div className="container-m mb-32 ">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
                <a href={"https://github.com/avstack-lab/avdev-sandbox/blob/main/README.md"} rel="noopener noreferrer" target="_blank">Install AV development sandbox</a>
                <MyCodeBlock
                    code={installAvdevSandbox}
                    language='bash'
                    showLineNumbers={false}
                    startingLineNumber='1'
                />
              </p>
            </div>
          <div className="container-m mb-32 ">
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
              <a href={"https://avstack.readthedocs.io"} rel="noopener noreferrer" target="_blank">Check us out on ReadTheDocs</a>
            </p>
          </div>
          {/* <div className="container-m mb-32 ">
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
              <a href={"https://github.com/avstack-lab/lib-avstack-api/blob/main/README.md"} rel="noopener noreferrer" target="_blank">Install API Library</a>
              <MyCodeBlock
                  code={InstallAPI}
                  language='bash'
                  showLineNumbers={false}
                  startingLineNumber='1'
              />
            </p>
          </div> */}
          <div className="container-m mb-32 ">
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
              <a href={"https://github.com/avstack-lab/carla-sandbox/blob/main/README.md"} rel="noopener noreferrer" target="_blank">Get started with Carla</a>
            </p>
            <p>
              Visit the <a href={"https://github.com/avstack-lab/carla-sandbox"} rel="noopener noreferrer" target="_blank">Carla-Sandbox repository</a> for the latest instructions.
            </p>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}

QuickStart.propTypes = propTypes;
QuickStart.defaultProps = defaultProps;

export default QuickStart;