import React from 'react';
// import sections
import FaqSec from '../components/sections/Faq';


const FAQ = () => {

  return (
    <>
      <FaqSec className="illustration-section-01" />
    </>
  );
}

export default FAQ;