import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../elements/Input';
import Button from '../elements/Button'
import database from '../../firebase'
import { collection, addDoc } from "firebase/firestore";
import Swal from 'sweetalert2'


// Function to validate email
function ValidateEmail(inputText) {
    var mailformat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return (inputText.match(mailformat) ? true : false);
  }
  

const propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  labelHidden: PropTypes.bool,
  type: PropTypes.oneOf(['textarea', 'text', 'email', 'tel', 'password', 'number', 'search', 'color', 'date', 'time', 'datetime-local']),
  name: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  formGroup: PropTypes.string,
  hasIcon: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  hint: PropTypes.string
}

const defaultProps = {
  children: null,
  label: '',
  labelHidden: false,
  type: 'email',
  name: undefined,
  status: '',
  disabled: false,
  value: undefined,
  formGroup: null,
  hasIcon: null,
  size: '',
  placeholder: '',
  rows: 3,
  hint: null
}


const EmailInput = ({
  ...props
}) => {

  // Declare state to capture email input
  const [email, setEmail] = useState('');

  // Handle the submit button
  const handleSubmit = async (event) => {
    // Check email for validity first
    if (!ValidateEmail(`${email}`)){
      alert('Invalid email')
    } else {
    event.preventDefault();
    try {
      const docRef = await addDoc(collection(database, "emails"), {
        email: email,    
      });
      console.log("Document written with ID: ", docRef.id);
      Swal.fire({
        icon: 'success',
        title: 'Thanks for subscribing!',
        text: "Be on the lookout for breaking changes!",
      })
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }};


  return (
    <>
      <div>
        <Input
          {...props}
          onChange={setEmail}
        />
        <div className="mt-16">
          <Button onClick={handleSubmit} wideMobile color="light">
            Submit
          </Button>
        </div>
      </div>
    </>
  )
}

EmailInput.propTypes = propTypes;
EmailInput.defaultProps = defaultProps;

export default EmailInput;