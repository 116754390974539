import React from "react";
import classNames from 'classnames';
import Faq from "react-faq-component";

const data = {
    title: "FAQ",
    rows: [
        {
          title: "What is AVstack?",
          content: `The idea of AVstack was conceived out of some opportunities (read: frustrations)
            from several autonomous vehicle (AV) research projects. Time and again when benchmarking new algorithms,
            testing new datasets, or performing a security analysis, we were asked: *integrate your new <insert project
            here> with an existing simulator/algorithm/dataset/foo-bar*. As much as I appreciate building the 
            academic corpus, I really dislike the process of understanding other people's (hacky) conventions enough
            to compare my work with theirs. Even worse than disparate conventions, if a different dataset were 
            involved, all bets were off; if a simulator were required: kiss reproducibility goodbye.
            In an age where tech giants dominate the (semi) open-source market for cutting-edge research-grade technology,
            we're here to establish some sanity. 

            AV research is not-so-slowly drifting away from its status as an accessible research topic.
            State-of-the-art "all-purpose" autonomous vehicle platforms (e.g., Apollo, Autoware) have
            tens-of-thousands of version control commits of changes in low-level programming languages.
            Picking up proficiency in a new platform is no small task, even for the most seasoned developer.
            With the increasing barrier of AV development, modern research has narrowed in to niche and debatably
            closed conversations. Our meta-analysis of recent results suggested that (i) recent algorithm performance
            gains are hyper-specific and marginal, (ii) transfer testing across datasets is simply too difficult,
            (iii) transfer testing *nearly never* occurs between datasets and simulators. Moreover, conventions
            used by benchmark datasets and simulators can vary wildly. This leads to the unfortunate consequences
            of: a large barrier to developing AV software, inflexibility in proposed "state of the art" results,
            and an inability to verify recent works with differing configurations or conventions.
          `
        },
        {
          title: "How do I install?",
          content: <a href="/#quickstart">Click here to see the quickstart guide</a>
          ,
        },
        {
          title: "How to I organize my datasets?",
          content: <a href="https://avstack.readthedocs.io/en/latest/" rel="noopener noreferrer" target="_blank">Click here to see the latest documentation</a>
          ,
        },
        {
          title: "Can I use a custom dataset?",
          content: <a href="https://avstack.readthedocs.io/en/latest/" rel="noopener noreferrer" target="_blank">Yes! Click here to see the latest documentation</a>
        },
        {
          title: "How do I run multi-agent configurations?",
          content: <a href="https://avstack.readthedocs.io/en/latest/" rel="noopener noreferrer" target="_blank">Click here to see the latest documentation</a>
        },
        {
          title: "Is this a replacement for ROS?",
          content: <p>No!</p>,
        },
    ],
};

const styles = {
    bgColor: 'primary',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: 'white',
    arrowColor: "red",
    rowContentPaddingLeft: '50px',
    rowContentPaddingRight: '50px',
};

const config = {
    animate: true,
    arrowIcon: "V",
    tabFocus: true
};



const FaqSec = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
  
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );
  
    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
  
    return (
    <section
    {...props}
    className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Frequently Asked Questions
          </h1>
          <div className="section-inner reveal-from-bottom"  style={{ textAlign: "left" }} data-reveal-delay="600">
            <Faq
            data={data}
            styles={styles}
            config={config}
            />
          </div>
        </div>
      </div>
    </section>
  );
}


export default FaqSec