import React from "react";
import classNames from 'classnames';
import SplitLeftRight from "../components/elements/SplitLeftRight";
import SplitRightLeft from "../components/elements/SplitRightLeft";

// Import media
import imgAvstack from '../assets/images/avstack-page-1.png'
import imgDemo from '../assets/images/demo-platform-page-1.png'

// AVstack citation
const citeAvstack = {
  text: `
    Hallyburton, R. S., Zhang, S., & Pajic, M. (2023, May).
    AVstack: An Open-Source, Reconfigurable Platform for Autonomous Vehicle Development.
    In Proceedings of the ACM/IEEE 14th International Conference on Cyber-Physical Systems (with CPS-IoT Week 2023)
    (pp. 209-220).
  `,
  link: "https://dl.acm.org/doi/abs/10.1145/3576841.3585930"
}
const splitAvstack = {
  titletext: <a href={citeAvstack.link} rel="noopener noreferrer" target="_blank"> { citeAvstack.text }</a> ,
  headtext: "",
  bodytext: "The original AVstack was published at ICCPS 2023. This first work describes the motivations for the platform's development and the key design decisions that enabled the open source, reconfigurable code base.",
  image: imgAvstack,
  width: 200,
  height: 400,
  imagelink: citeAvstack.link,
}

// Demo platform citation
const citeDemo = {
  text: `
    Hallyburton, R. S., Zelter, N., Hunt, D., Angell, K., & Pajic, M. (2023, May). 
    A Modular Platform For Collaborative, Distributed Sensor Fusion. 
    In Proceedings of the ACM/IEEE 14th International Conference on Cyber-Physical Systems (with CPS-IoT Week 2023) 
    (pp. 268-269).
  `,
  link: "https://dl.acm.org/doi/abs/10.1145/3576841.3589630",
}
const splitDemo = {
  titletext: <a href={citeDemo.link} rel="noopener noreferrer" target="_blank"> { citeDemo.text }</a>,
  headtext: "",
  bodytext: "The modular demo platform is a near-real-time, physical sensing system build using AVstack and ZeroMQ. It runs with multiple sensor types including cameras and radars." ,
  image: imgDemo,
  width: 200,
  height: 400,
  imagelink: citeDemo.link,
}


const Research = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );


  return (
    <section
    {...props}
    className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Publications
          </h1>
          <div className={splitClasses}>
            <SplitLeftRight
              titletext={splitAvstack.titletext}
              headertext={splitAvstack.headertext}
              bodytext={splitAvstack.bodytext}
              src={splitAvstack.image}
              width={splitAvstack.width}
              height={splitAvstack.height}
              imageLink={splitAvstack.imagelink}
              imageFill={imageFill}
            />
            <SplitRightLeft
              titletext={splitDemo.titletext}
              headertext={splitDemo.headertext}
              bodytext={splitDemo.bodytext}
              src={splitDemo.image}
              width={splitDemo.width}
              height={splitDemo.height}
              imageLink={splitDemo.imagelink}
              imageFill={imageFill}
            />
          </div>
          <h1 className="mt-64 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Research Initiatives
          </h1>
          <div className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="300">
            <p>Coming soon!</p>
          </div>
        </div>
      </div>
  </section>
  );
}


export default Research;