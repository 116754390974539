import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

// Import media
import imgLogo1 from './../../assets/images/LogoTemp.png'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const imgWidth = 200
const imgHeight = 200

const TripleLogo = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

    const outerClasses = classNames(
      'logos-tiles',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );
  
    const innerClasses = classNames(
      'logos-tiles-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
  
    const tilesClasses = classNames(
      'tiles-wrap center-content',
      pushLeft && 'push-left'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={tilesClasses}>

              {/* Tile 1 */}
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="logos-tiles-item-header">
                    <div className="logos-tiles-item-image mb-16">
                      <a href={"https://github.com/avstack-lab/lib-avstack-core"} rel="noopener noreferrer" target="_blank">
                        <Image
                          src={imgLogo1}
                          alt="logos tile icon 01"
                          width={imgWidth}
                          height={imgHeight} />
                      </a>
                    </div>
                  </div>
                  <div className="logos-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <a href={"https://github.com/avstack-lab/lib-avstack-core"} rel="noopener noreferrer" target="_blank">CORE</a>
                    </h4>
                  </div>
                </div>
              </div>

              {/* Tile 2 */}
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="logos-tiles-item-header">
                    <div className="logos-tiles-item-image mb-16">
                      <a href={"https://github.com/avstack-lab/lib-avstack-api"} rel="noopener noreferrer" target="_blank">
                        <Image
                          src={imgLogo1}
                          alt="logos tile icon 02"
                          width={imgWidth}
                          height={imgHeight} />
                      </a>
                    </div>
                  </div>
                  <div className="logos-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <a href={"https://github.com/avstack-lab/lib-avstack-api"} rel="noopener noreferrer" target="_blank">API</a>
                    </h4>
                  </div>
                </div>
              </div>

              {/* Tile 3 */}
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="logos-tiles-item-header">
                    <div className="logos-tiles-item-image mb-16">
                      <a href={"https://github.com/avstack-lab/avdev-sandbox"} rel="noopener noreferrer" target="_blank">
                        <Image
                          src={imgLogo1}
                          alt="logos tile icon 03"
                          width={imgWidth}
                          height={imgHeight} />
                      </a>
                    </div>
                  </div>
                  <div className="logos-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      <a href={"https://github.com/avstack-lab/avdev-sandbox"} rel="noopener noreferrer" target="_blank">SANDBOX</a>
                    </h4>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
    </section>
  );
}
TripleLogo.propTypes = propTypes;
TripleLogo.defaultProps = defaultProps;

export default TripleLogo;