import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import SplitLeftRight from '../elements/SplitLeftRight';
import SplitRightLeft from '../elements/SplitRightLeft';

// Import media
import imgModular from './../../assets/images/modular-arch-01.png'
import imgMultiAgent from './../../assets/images/multi-agent-01.png'
import imgCarla from './../../assets/images/carla-weather.jpg'


const splitModular = {
  titletext: "Designs that work for you",
  headertext: "Reconfigrable Architectures",
  bodytext: "Testing perception and tracking algorithms? Evaluating multi-rate, multi-sensor fusion? Benchmarking closed-loop, Level-5 self-driving? Right on. We dig that.",
  image: imgModular,
  width: 528,
  height: 396,
}

const splitMulti = {
  titletext: "Next-Gen Analysis",
  headertext: "Multi-Sensor, Multi-Agent",
  bodytext: "With simple frameworks to test multi-sensor, vehicle-to-vehicle (V2V), and vehicle-to-infrastructure (V2I) configurations, AVstack answers the mail for next-generation AV analysis.",
  image: imgMultiAgent,
  width: 528,
  height: 396,
}

const splitCarla = {
  titletext: "Simulation without the hassel",
  headertext: "Closed-Loop Simulation",
  bodytext: "Compatible with the state of the art in physics-based simulation, including Carla (right), AVstack is a one-stop-shop for pushing AVs to the next level.",
  image: imgCarla,
  width: 528,
  height: 396,
}

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner reveal-from-bottom',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'More Time Developing',
    paragraph: '...and less time wrangling, wrapping, and wrestling with your patchwork of tools. We\'ve all been there before.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} data-reveal-delay="200">
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <SplitLeftRight
              titletext={splitModular.titletext}
              headertext={splitModular.headertext}
              bodytext={splitModular.bodytext}
              src={splitModular.image}
              width={splitModular.width}
              height={splitModular.height}
              imageFill={imageFill}
            />

            <SplitRightLeft
              titletext={splitMulti.titletext}
              headertext={splitMulti.headertext}
              bodytext={splitMulti.bodytext}
              src={splitMulti.image}
              width={splitMulti.width}
              height={splitMulti.height}
              imageFill={imageFill}
            />

            <SplitLeftRight
              titletext={splitCarla.titletext}
              headertext={splitCarla.headertext}
              bodytext={splitCarla.bodytext}
              src={splitCarla.image}
              width={splitCarla.width}
              height={splitCarla.height}
              imageFill={imageFill}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;